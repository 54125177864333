import $axios from '@/plugins/axios'

export default {
  state: {
    reviews: {
      data: {
        daily: 0,
        weekly: 0,
        monthly: 0
      }
    },
    reservationsStatistic: {
      data: {
        daily: 0,
        weekly: 0,
        monthly: 0
      }
    },
    likes: {
      data: {
        daily: 0,
        weekly: 0,
        monthly: 0
      }
    },
    comment: []
  },
  getters: {
    reviews: state => state.reviews,
    reservationsStatistic: state => state.reservationsStatistic,
    likes: state => state.likes,
    comment: state => state.comment
  },
  actions: {
    async hotelReviews ({ commit }, page = 1) {
      const response = await $axios.get('/hotel-views-statistics')
      commit('SET_REVIEWS', response.data)
    },
    async hotelReservations ({ commit }, page = 1) {
      const response = await $axios.get('/hotel-reservations-statistics')
      commit('SET_RESER', response.data)
    },
    async hotelLikes ({ commit }, page = 1) {
      const response = await $axios.get('/hotel-likes-statistics')
      commit('SET_LIKES', response.data)
    },
    async hotelComment ({ commit }, page = 1) {
      const response = await $axios.get('/hotel-comments?include=client')
      commit('SET_COMMENT', response.data)
    }
  },
  mutations: {
    SET_REVIEWS (state, data) {
      state.reviews = data
    },
    SET_RESER (state, data) {
      state.reservationsStatistic = data
    },
    SET_LIKES (state, data) {
      state.likes = data
    },
    SET_COMMENT (state, data) {
      state.comment = data
    }
  }
}
