import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import Ecommerce from './Ecommerce/index'
import auth from './modules/auth'
import hotels from './modules/hotels'
import governorate from './modules/governorate'
import cities from './modules/cities'
import services from './modules/services'
import features from './modules/features'
import landmarks from './modules/landmark'
import Permissions from './modules/permissions'
import Roles from './modules/roles'
import Users from './modules/users'
import Floors from './modules/floors'
import Rooms from './modules/rooms'
import Prices from './modules/price'
import Reservations from './modules/reservations'
import Discounts from './modules/discount'
import Statistic from './modules/statistic'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    Ecommerce,
    auth,
    hotels,
    governorate,
    cities,
    services,
    features,
    landmarks,
    Permissions,
    Roles,
    Users,
    Floors,
    Rooms,
    Prices,
    Reservations,
    Discounts,
    Statistic
  },
  state: {
    loaderState: false
  },
  mutations: {
    changeLoaderStatus (state, data) {
      state.loaderState = data
    }
  },
  actions: {
  },
  getters: {
    loaderState: (state) => state.loaderState
  },
  strict: debug
})
