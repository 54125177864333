import $axios from '@/plugins/axios'
// const model = 'landmarks'
export default {
  state: {
    priceList: [],
    roomPrice: []
  },
  getters: {
    priceList: state => state.priceList,
    roomPrice: state => state.roomPrice
  },
  actions: {
    async getPriceList ({ commit }) {
      const response = await $axios.get('/price')
      commit('SET_PRICE_LIST', response.data)
    },
    async getRoomPriceList ({ commit }, payload) {
      const response = await $axios.get(`/prices/room/${payload.id}?take=100`, {
        params: payload.query
      })
      commit('SET_ROOM_PRICE', response.data)
    },
    async sortRoomPrice ({ commit }, payload) {
      await $axios.post('/price', payload)
    },
    async updateRoomPrice ({ commit }, payload) {
      await $axios.post(`/room-price/${payload.id}`, {
        price: payload.price
      })
    }
    // async addLandmarks ({ commit }, payload) {
    //   const response = await $axios.post(`/${model}`, payload)
    //   commit('SORT_LANDMARKS', response.data.data)
    // },

    // async updateLandmarks ({ commit }, payload) {
    //   const response = await $axios.put(`/${model}/${payload.id}`, payload)
    //   commit('UPDATE_LANDMARKS', response.data.data)
    // },

    // async removeLandmarks ({ commit }, id) {
    //   const response = await $axios.delete(`/${model}/${id}`)
    //   commit('REMOVE_LANDMARKS', response.data.data.id)
    // }
  },
  mutations: {
    SET_PRICE_LIST (state, data) {
      state.priceList = data
    },
    SET_ROOM_PRICE (state, data) {
      state.roomPrice = data
    }
    // SORT_LANDMARKS (state, data) {
    //   state.landmarks.data.push(data)
    // },
    // UPDATE_LANDMARKS (state, data) {
    //   state.landmarks.data = state.landmarks.data.map(item => item.id === data.id ? data : item)
    // },
    // REMOVE_LANDMARKS (state, id) {
    //   state.landmarks.data = state.landmarks.data.filter(x => x.id !== id)
    // }

  }
}
