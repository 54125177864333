import $axios from '@/plugins/axios'
const model = 'floors'
export default {
  state: {
    floors: []
  },
  getters: {
    floors: state => state.floors
  },
  actions: {
    async getFloors ({ commit }, filterData = { take: 10, skip: 0, name: '' }) {
      const response = await $axios.get(`/${model}?take=100`)
      commit('SET_DATA', response.data)
    }
    // async addServices ({ commit }, payload) {
    //   const response = await $axios.post(`/${model}`, payload)
    //   commit('SORT_DATA', response.data.data)
    // },

    // async updateServices ({ commit }, payload) {
    //   const response = await $axios.put(`/${model}/${payload.id}`, payload)
    //   commit('UPDATE_DATA', response.data.data)
    // },

    // async removeServices ({ commit }, payload) {
    //   const response = await $axios.delete(`/${model}/${payload.id}`)
    //   commit('REMOVE_DATA', response.data.data.id)
    // }
  },
  mutations: {
    SET_DATA (state, data) {
      state.floors = data
    }
    // SORT_DATA (state, data) {
    //   return {
    //     state, data
    //   }
    //   state.governorates.data.push(data)
    // },
    // UPDATE_DATA (state, data) {
    //   return {
    //     state, data
    //   }
    //   // state.abouts = state.abouts.map(item => item = item.id == about.id ? about : item)
    // },
    // REMOVE_DATA (state, id) {
    //   return {
    //     state, id
    //   }
    //   // state.abouts = state.abouts.filter(x => x.id !== id)
    // }

  }
}
