import $axios from '@/plugins/axios'
const model = 'discount'
export default {
  state: {
    discounts: []
  },
  getters: {
    discounts: state => state.discounts
  },
  actions: {
    async getDiscounts ({ commit }, filterData = { page: 1 }) {
      const response = await $axios.get(`/${model}?take=10&page=${filterData.page}`)
      commit('SET_DISCOUNTS', response.data)
    },
    async addDiscounts (_, payload) {
      return await $axios.post(`/${model}`, payload)
    },

    async updateDiscounts (_, payload) {
      return await $axios.put(`/${model}/${payload.id}`, payload)
    },

    async removeDiscounts (_, id) {
      return await $axios.delete(`/${model}/${id}`)
    }
  },
  mutations: {
    SET_DISCOUNTS (state, data) {
      state.discounts = data
    },
    SORT_DISCOUNTS (state, data) {
      state.discounts.data.push(data)
    },
    UPDATE_DISCOUNTS (state, data) {
      state.discounts.data = state.discounts.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_DISCOUNTS (state, id) {
      state.discounts.data = state.discounts.data.filter(x => x.id !== id)
    }

  }
}
