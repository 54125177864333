import $axios from '@/plugins/axios'
export default {
  state: {
    userData: {}
  },
  getters: {
    userData: state => state.userData
  },
  actions: {
    async loginUser ({ commit }, payload) {
      const response = await $axios.post('/auth/login', payload)
      commit('SET_USER', response.data)
    },
    async logoutUser ({ commit }) {
      await $axios.post('/auth/logout')
      commit('REMOVE_USER')
    },
    async registerHotel ({ commit }, payload) {
      const response = await $axios.post('/auth/register', payload)
      commit('SET_USER', response.data)
    }
  },
  mutations: {
    SET_USER (state, data) {
      state.userData = data
      localStorage.setItem('booking-hotel-info', JSON.stringify(data))
    },
    REMOVE_USER (state) {
      state.userData = {}
    }

  }
}
