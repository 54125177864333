import $axios from '@/plugins/axios'
const model = 'features'
export default {
  state: {
    features: {
      data: []
    }
  },
  getters: {
    features: state => state.features
  },
  actions: {
    async getFeatures ({ commit }, page = 1) {
      const response = await $axios.get(`/${model}?include=features&take=10&page=${page}`)
      commit('SET_FEATURES', response.data)
    },
    async addHotelFeatures ({ commit }, payload) {
      const response = await $axios.post('/my-hotel-features', payload)
      return { commit, response }
    }
  },
  mutations: {
    SET_FEATURES (state, data) {
      state.features = data
    }
  }
}
