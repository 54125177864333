import $axios from '@/plugins/axios'
const model = 'services'
export default {
  state: {
    services: []
  },
  getters: {
    services: state => state.services
  },
  actions: {
    async getServices ({ commit }, page = 1) {
      const response = await $axios.get(`/${model}?take=100&page=${page}`)
      commit('SET_SERVICES', response.data)
    },
    async getServicesRoom ({ commit }, page = 1) {
      const response = await $axios.get(`/${model}?take=100&type=1&filter[service_type]=1`)
      commit('SET_SERVICES', response.data)
    },
    async addHotelServices ({ commit }, payload) {
      const response = await $axios.post('/hotelService', payload)
      return { commit, response }
    },
    async addRoomServices (_, payload) {
      return await $axios.put(`/services/room/${payload.id}`, payload.servicesIds)
    }
  },
  mutations: {
    SET_SERVICES (state, data) {
      state.services = data
    }
  }
}
