import axios from 'axios'
import store from '@/store/index'
import { core } from '@/config/pluginInit'
import router from '@/router'
// import Swal from 'sweetalert2/dist/sweetalert2.js'
axios.interceptors.request.use(config => {
  // config.baseURL = 'http://3.12.41.93:5650/api/hotel-dashboard'
  config.baseURL = 'https://api.bookingiraq.com/api/hotel-dashboard'
  config.headers = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('booking-hotel-info') !== null ? JSON.parse(localStorage.getItem('booking-hotel-info')).token : ''}`
  }
  store.commit('changeLoaderStatus', true)
  return config
})

axios.interceptors.response.use((response) => {
  if (response.config.method !== 'get') {
    core.showSnackbar('success', 'Process Complete')
  }
  store.commit('changeLoaderStatus', false)

  return response
}, (error) => {
  // error.config.method == 'get' ? store.state.getLoading = false : ''
  if (error.response.status === 400) {
    var errorMsg = ''
    for (var key in error.response.data) {
      errorMsg += `-${error.response.data[key][0]} \n`
    }
    core.showSnackbar('error', errorMsg)
  } else if (error.response.status === 401) {
    core.showSnackbar('error', 'Login credentials are invalid.')
    localStorage.removeItem('booking-admin-info')
    router.push('/login')
  }
  store.commit('changeLoaderStatus', false)
  return Promise.reject(error)
}
)

export default axios
