import $axios from '@/plugins/axios'
const model = 'landmarks'
export default {
  state: {
    landmarks: []
  },
  getters: {
    landmarks: state => state.landmarks
  },
  actions: {
    async getLandmarks ({ commit }, filterData = { take: 10, skip: 0, name: '' }) {
      const response = await $axios.get(`/${model}?include=createdBy,updatedBy&take=${filterData.take}&skip=${filterData.skip}&filter[ar_name]=${filterData.name}`)
      commit('SET_LANDMARKS', response.data)
    },
    async addLandmarks ({ commit }, payload) {
      const response = await $axios.post(`/${model}`, payload)
      commit('SORT_LANDMARKS', response.data.data)
    },

    async updateLandmarks ({ commit }, payload) {
      const response = await $axios.put(`/${model}/${payload.id}`, payload)
      commit('UPDATE_LANDMARKS', response.data.data)
    },

    async removeLandmarks ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_LANDMARKS', response.data.data.id)
    }
  },
  mutations: {
    SET_LANDMARKS (state, data) {
      state.landmarks = data
    },
    SORT_LANDMARKS (state, data) {
      state.landmarks.data.push(data)
    },
    UPDATE_LANDMARKS (state, data) {
      state.landmarks.data = state.landmarks.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_LANDMARKS (state, id) {
      state.landmarks.data = state.landmarks.data.filter(x => x.id !== id)
    }

  }
}
