import $axios from '@/plugins/axios'
const model = 'hotel'
export default {
  state: {
    hotels: [],
    hotel: {
      city: {},
      services: [],
      features: []
    },
    hotelImgs: []
  },
  getters: {
    hotels: state => state.hotels,
    hotel: state => state.hotel,
    hotelImgs: state => state.hotelImgs
  },
  actions: {
    async getHotels ({ commit }, filterData = { take: 10, skip: 0, title: '' }) {
      const response = await $axios.get(`/${model}?include=governorate,city,createdBy,updatedBy&take=${filterData.take}&skip=${filterData.skip}&filter[title]=${filterData.title}`)
      commit('SET_HOTELS', response.data)
    },
    async getHotel ({ commit }) {
      const response = await $axios.get(`/${model}?include=governorate,city,services,features`)
      commit('SET_HOTEL', response.data)
    },
    async getHotelImg ({ commit }) {
      const response = await $axios.get('/my-hotel-image?take=30')
      commit('SET_HOTEL_IMG', response.data.data)
      return response
    },
    async addHotelImg (_, payload) {
      const response = await $axios.post('/my-hotel-upload-image', payload)
      return { response }
    },
    async addHotels ({ commit }, payload) {
      const response = await $axios.post(`/${model}`, payload)
      return { commit, response }
    },

    async updateHotels ({ commit }, payload) {
      const response = await $axios.put('/my-hotel', payload)
      return { commit, response }
    },
    async removeHotels ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_HOTELS', response.data.data.id)
    },
    async showOrHideImage ({ commit }, id) {
      const response = await $axios.put(`/show-and-hide-image/${id}`)
      commit('SET_HOTEL_IMG', response.data.data.id)
    },
    async assignPermissionsToUsers ({ commit }, payload) {
      const response = await $axios.post(`/${model}/${payload.userId}`, payload.permissions)
      return { commit, response }
      // commit('REMOVE_GOVERNORATES', response.data.data.id)
    },
    async removeHotelImg (_, id) {
      return await $axios.delete(`/my-hotel-image/${id}`)
    }
  },
  mutations: {
    SET_HOTELS (state, data) {
      state.hotels = data.data
    },
    SET_HOTEL (state, data) {
      state.hotel = data.data
    },
    SET_HOTEL_IMG (state, data) {
      state.hotelImgs = data
    },
    SORT_HOTELS (state, data) {
      state.hotels.data.push(data)
    },
    UPDATE_HOTELS (state, data) {
      state.hotels.data = state.hotels.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_HOTELS (state, id) {
      state.hotels.data = state.hotels.data.filter(x => x.id !== id)
    }
  }
}
