import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
const Layout3 = () => import('../layouts/Layout3.vue')
const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')
/* Dashboards View */
const Dashboard3 = () => import('../views/Dashboards/Dashboard3')

/* Authentic View */
const SignIn1 = () => import('../views/AuthPages/Default/SignIn1')
const SignUp1 = () => import('../views/AuthPages/Default/SignUp1')
const RecoverPassword1 = () => import('../views/AuthPages/Default/RecoverPassword1')
const LockScreen1 = () => import('../views/AuthPages/Default/LockScreen1')
const Hotel = () => import('../views/Hotel/index.vue')
const Feature = () => import('../views/Feature/index.vue')
const Services = () => import('../views/Services/index.vue')
const Imgs = () => import('../views/Imgs/index.vue')
const Rooms = () => import('../views/Rooms/index.vue')
const AddRoom = () => import('../views/Rooms/add-room.vue')
const RoomDetails = () => import('../views/Rooms/room-details.vue')
const Reservations = () => import('../views/Reservations/reservations.vue')
const ReservationsTable = () => import('../views/Reservations/reservations-table.vue')
const ReservationsInfo = () => import('../views/Reservations/reservation-info.vue')
const Price = () => import('../views/Price/index.vue')
const Discounts = () => import('../views/Discounts/index.vue')

Vue.use(VueRouter)

const authChildRoutes = (prop) => [
  {
    path: '/login',
    name: prop + '.sign-in1',
    meta: { auth: true },
    component: SignIn1
  },
  {
    path: 'sign-up1',
    name: prop + '.sign-up1',
    meta: { auth: true },
    component: SignUp1
  },
  {
    path: 'password-reset1',
    name: prop + '.password-reset1',
    meta: { auth: true },
    component: RecoverPassword1
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    meta: { auth: true },
    component: LockScreen1
  }
]

// const pagesChildRoutes = (prop) => []

const routes = [
  {
    path: '/',
    component: Layout3,
    meta: { auth: true },
    children: [
      {
        path: '',
        meta: { auth: true, name: 'Home 3' },
        component: Dashboard3
      },
      {
        path: '/hotel',
        meta: { auth: true, name: 'hotel' },
        component: Hotel
      },
      {
        path: '/hotel-feature',
        meta: { auth: true, name: 'feature' },
        component: Feature
      },
      {
        path: '/hotel-services',
        meta: { auth: true, name: 'services' },
        component: Services
      },
      {
        path: '/hotel-rooms',
        meta: { auth: true, name: 'rooms' },
        component: Rooms
      },
      {
        path: '/add-rooms/:id?',
        meta: { auth: true, name: 'add-room' },
        component: AddRoom
      },
      {
        path: '/room-details/:id',
        meta: { auth: true, name: 'rooms-details' },
        component: RoomDetails
      },
      {
        path: '/hotel-imgs',
        meta: { auth: true, name: 'imgs' },
        component: Imgs
      },
      {
        path: '/rooms-price/:id?',
        meta: { auth: true, name: 'price' },
        component: Price
      },
      {
        path: '/reservations',
        meta: { auth: true, name: 'reservations-rooms' },
        component: Reservations
      },
      {
        path: '/reservations-table',
        meta: { auth: true, name: 'reservations-table' },
        component: ReservationsTable
      },
      {
        path: '/reservations-info/:id?',
        meta: { auth: true, name: 'reservations-info' },
        component: ReservationsInfo
      },
      {
        path: '/discounts',
        meta: { auth: true, name: 'discounts' },
        component: Discounts
      }
    ]
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/sign-in1', '/auth/sign-up1', '/dark/auth/sign-in1', '/dark/auth/sign-up1']
  if (publicPages.includes(to.path)) {
    localStorage.removeItem('user')
    localStorage.removeItem('access_token')
  }
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (to.meta.auth) {
    if (authRequired && loggedIn === null) {
      return next()
    } else if (to.name === 'dashboard' || to.name === 'dashboard.home-1') {
      return next()
    }
  }
  next()
})

export default router
